<template>
  <div>
    <h1 class="headline--md text-bold text-uppercase">Create a Request</h1>

    <div class="box box--md box--white mt-16">
      <table class="table table--list three-items">
        <tbody>
          <tr>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Withdraw request amount (USDT)</p>
                <div class="form-group">
                  <input
                    v-model="$v.params.amount.$model"
                    type="text"
                    :class="['form-control', { 'form-control--error': $v.params.amount.$anyError }]"
                    @keydown.enter="onHandleMetamaskConnectedModal"
                  />
                  <p v-if="$v.params.amount.$anyError" class="form-error-text">
                    <span v-if="!$v.params.amount.required">Amount can't be blank</span>
                    <span v-else>Amount should larger than 0</span>
                  </p>
                </div>
              </div>
            </td>

            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Request note</p>
                <div class="form-group">
                  <textarea
                    v-model="params.requestNote"
                    rows="1"
                    cols="50"
                    type="textarea"
                    class="form-control"
                    @keydown.enter="onHandleMetamaskConnectedModal"
                  />
                </div>
              </div>
            </td>
            <td />
          </tr>
        </tbody>
      </table>
    </div>

    <div class="d-flex justify-end mt-16">
      <div class="btn-group">
        <button class="btn btn--purple-bordered" @click="$route.go(-1)">Cancel</button>
        <button class="btn btn--primary" @click="onHandleMetamaskConnectedModal">Create</button>
      </div>
    </div>

    <loading :open="waiting" />

    <confirm-dialog
      v-if="isNotiConnectMetamaskOpen && !isConnected"
      :open.sync="isNotiConnectMetamaskOpen"
      @confirm="onAddRequestTransfer"
      confirmText="You need install and connect Metamask on this app to conitinue this action!"
      confirmButtonText="OK"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import withdrawFormValidator from '@/validations/withdraw-form'
import * as message from '@/store/support/message'
import unixTime from '@/mixins/unix-time'
import { SMART_CONTRACT_TYPE } from '@/utils/key-mapping'
const Loading = () => import('@/components/Loading')
const ConfirmDialog = () => import('@/components/ConfirmDialog')

export default {
  data() {
    return {
      params: {
        requestNote: '',
        amount: ''
      },
      isNotiConnectMetamaskOpen: false,
      smartContractType: SMART_CONTRACT_TYPE,
      dateContext: {
        startDate: new Date(),
        endDate: new Date()
      },
      localCrowdfundingBalance: null
    }
  },

  mixins: [withdrawFormValidator, unixTime],

  components: {
    Loading,
    ConfirmDialog
  },

  computed: {
    ...mapState('dapp', ['waiting', 'isConnected']),

    ...mapState('smartContract', ['usdtSmartContract'])
  },

  methods: {
    ...mapActions('requestTransfer', ['addRequestTransfer']),

    ...mapActions('smartContract', ['getSmartContracts']),

    ...mapActions('dapp', ['web3Connect', 'getDappAccount', 'requestTransferByAdmin']),

    ...mapActions('program', ['getProgramBalances']),

    ...mapMutations('alert', ['STORE_ALERT']),

    requestTransfer() {
      this.requestTransferByAdmin({ amount: this.params.amount })
    },

    onHandleMetamaskConnectedModal() {
      if (this.isConnected) return this.onAddRequestTransfer()
      this.isNotiConnectMetamaskOpen = true
    },

    getReportDateRequest(dateRange) {
      const reportDate = this.$formatDateTime(dateRange).getDateRequest().toDate
      return this.getTimestamp(reportDate)
    },

    async onAddRequestTransfer() {
      this.isNotiConnectMetamaskOpen = false
      this.$v.$touch()
      if (!this.isFormValid) return

      if (this.params.amount > this.localCrowdfundingBalance.total_balance)
        return alert('Your amount request is larger than current balance!')

      await this.web3Connect()
      if (!this.isConnected)
        return this.STORE_ALERT({ type: 'error', content: message.METAMASK_NOT_INSTALLED, timeOut: 2400 })
      this.addRequestTransfer(this.params)
    },

    async onGetCrowdfundingProgramBalance() {
      const reportDate = this.getReportDateRequest(this.dateContext)
      const response = await this.getProgramBalances({
        startDate: 0,
        endDate: reportDate,
        smartContractType: this.smartContractType.crowdfunding
      })
      this.localCrowdfundingBalance = response.data.result.data[0]
    }
  },

  async created() {
    await this.getSmartContracts()
    await this.onGetCrowdfundingProgramBalance()
  }
}
</script>
